<template>
    <L404Section />
</template>

<script>
import L404Section from "../../components/Landing/L404Section.vue"

export default {
    name: "NotFoundPage",
    components: { L404Section },
}
</script>
<style scoped></style>
